import { BsExclamationTriangle } from 'react-icons/bs'
import { DownloadIcon, PencilIcon, PlusIcon, TrashIcon } from '@heroicons/react/outline'
import { FiSearch } from 'react-icons/fi'
import { IoHammerOutline } from 'react-icons/io5'
import { PriceOfferShort } from '../../../../services/api/price-offer/PriceOffer.types'
import { Row } from '@tanstack/react-table'
import { useDemandTableContext } from '../../demand/table/DemandTable.context'
import { usePriceOfferTableContext } from './PriceOfferTable.context'
import { useRouter } from 'next/router'
import { useTranslation } from 'next-i18next'
import Button from '../../../base/button/Button'
import ConfirmDialog from '../../../base/confirm-dialog/ConfirmDialog'
import Link from 'next/link'
import PriceOfferAddVariantDialog from './PriceOfferAddVariantDialog'
import PriceOfferPdfDownload from '../pdf/PriceOfferPdfDownload'
import PriceOfferSendToProductionModal from '../send-to-production-modal/PriceOfferSendToProductionModal'
import React, { useMemo, useState } from 'react'
import Tooltip from '../../../base/tooltip/Tooltip'
import usePriceOfferAddOrders from '../../../../hooks/api/price-offer/usePriceOfferAddOrders'
import usePriceOfferCreateVariant from '../../../../hooks/api/price-offer/usePriceOfferCreateVariant'
import usePriceOfferDelete from '../../../../hooks/api/price-offer/usePriceOfferDelete'
import useToast from '../../../../hooks/useToast'

export type PriceOfferTableActionsCellProps = {
    row: Row<PriceOfferShort>
    isPriceOfferDialog?: boolean
    onSuccessSendToProduction: () => void
}

const PriceOfferTableActionsCell = ({
    row: { original: priceOffer },
    isPriceOfferDialog = false,
    onSuccessSendToProduction
}: PriceOfferTableActionsCellProps) => {
    const { id, variants, state, orders, name, number, everythingRequiredFilled } = priceOffer
    const { t } = useTranslation()
    const { push } = useRouter()
    const toast = useToast()
    const { getSelectedVariant } = usePriceOfferTableContext()
    const { mutate: deletePriceOffer, isLoading: isDeletingPriceOffer } = usePriceOfferDelete()
    const { mutate: createPriceOfferVariant, isLoading: isLoadingCreatingPriceOffer } = usePriceOfferCreateVariant()
    const { mutate: addOrdersToPriceOffer } = usePriceOfferAddOrders()
    const { setOfferPriceSelector, offerPriceSelector } = useDemandTableContext()
    const [isModalVisible, setIsModalVisible] = useState(false)
    const [isAddVariantModalVisible, setIsAddVariantModalVisible] = useState(false)

    const variant = useMemo<PriceOfferShort>(() => {
        const variant = getSelectedVariant(id, [priceOffer, ...priceOffer.variants])
        if (variant === '0') {
            return priceOffer
        }
        return variants.find(item => {
            return item.variant === variant
        })
    }, [getSelectedVariant, id, priceOffer, variants])

    const onClickDelete = () => {
        return deletePriceOffer(variant.id)
    }

    const onClickCreateVariant = () => {
        const request = {
            copyOrders: true
        }
        createPriceOfferVariant(
            {
                id: variant.id,
                request: request
            },
            {
                onSuccess: data => {
                    setIsAddVariantModalVisible(false)
                    push(`/app/price-offers/create/${data.id}`)
                }
            }
        )
    }

    const onClickCreateConnectVariant = () => {
        const request = {
            copyOrders: false
        }
        createPriceOfferVariant(
            {
                id: variant.id,
                request: request
            },
            {
                onSuccess: data => {
                    setIsAddVariantModalVisible(false)
                    push(`/app/price-offers/create/${data.id}`)
                }
            }
        )
    }

    const onClickAddDemandsToPriceOffer = () => {
        addOrdersToPriceOffer(
            {
                id: id,
                orders: offerPriceSelector.selectedItems
            },
            {
                onSuccess: () => {
                    toast.success(t('frontend.dashboard.demand.table.action.info.message'))
                    setOfferPriceSelector({ show: false })
                    return push(`/app/price-offers/create/${id}`)
                },
                onError: () => {
                    toast.error(t('frontend.dashboard.demand.table.action.info.error'))
                }
            }
        )
    }

    if (isPriceOfferDialog) {
        return (
            <Button variant='normal' loading={isDeletingPriceOffer} onClick={onClickAddDemandsToPriceOffer}>
                {t<string>('frontend.dashboard.price_offers.table.action.add')}
            </Button>
        )
    }

    return (
        <div className='inline-flex flex-col gap-2.5'>
            {state === 'saved' && (
                <div className='flex flex-row items-center gap-3'>
                    <Button
                        className='!text-xs !w-[100px]'
                        as={Link}
                        variant='outlined'
                        color='secondary'
                        leftIcon={<PencilIcon />}
                        href={`/app/price-offers/create/${variant.id}`}
                    >
                        {t<string>('frontend.dashboard.price_offers.table.action.edit')}
                    </Button>
                    {priceOffer.hasGhost && (
                        <Tooltip
                            placement='top'
                            content={t('frontend.dashboard.priceOffer.table.status.draft.tooltip')}
                            textColor='primary'
                            lazy={false}
                        >
                            <BsExclamationTriangle className='h-4 w-4 text-red-700' />
                        </Tooltip>
                    )}
                </div>
            )}

            <div className='flex flex-wrap gap-2.5'>
                {everythingRequiredFilled && (
                    <Tooltip content='Detail cenové nabídky'>
                        <Button icon variant='text' as={Link} href={`/app/price-offers/detail/${variant.id}`}>
                            <FiSearch />
                        </Button>
                    </Tooltip>
                )}

                <Tooltip content={t<string>('frontend.dashboard.price_offers.table.action.download')}>
                    {everythingRequiredFilled && (
                        <PriceOfferPdfDownload id={variant.id} everythingRequiredFilled={everythingRequiredFilled}>
                            {(download, isLoading) => {
                                return (
                                    <Button icon variant='text' loading={isLoading} onClick={download}>
                                        <DownloadIcon />
                                    </Button>
                                )
                            }}
                        </PriceOfferPdfDownload>
                    )}
                </Tooltip>
                {state === 'saved' && (
                    <Tooltip content={t<string>('frontend.dashboard.price_offers.table.action.create_variant')}>
                        <Button variant='text' icon onClick={() => setIsAddVariantModalVisible(true)}>
                            <PlusIcon />
                        </Button>
                    </Tooltip>
                )}
                {state === 'saved' && (
                    <>
                        {everythingRequiredFilled ? (
                            <Tooltip
                                content={t<string>('frontend.price_offers.create.step_3.actions.send_to_production')}
                            >
                                <Button
                                    variant='text'
                                    icon
                                    onClick={() => {
                                        return setIsModalVisible(true)
                                    }}
                                >
                                    <IoHammerOutline />
                                </Button>
                            </Tooltip>
                        ) : (
                            <ConfirmDialog
                                text={t('frontend.price_offers.sent_to_production.modal.title')}
                                okLabel={t('frontend.shared.boolean.true')}
                                onConfirm={() => {
                                    return setIsModalVisible(true)
                                }}
                            >
                                {open => {
                                    return (
                                        <Tooltip
                                            content={t<string>(
                                                'frontend.price_offers.create.step_3.actions.send_to_production'
                                            )}
                                        >
                                            <Button variant='text' icon onClick={open}>
                                                <IoHammerOutline />
                                            </Button>
                                        </Tooltip>
                                    )
                                }}
                            </ConfirmDialog>
                        )}
                    </>
                )}
                <ConfirmDialog
                    text={t('frontend.dashboard.price_offers.table.action.delete_dialog')}
                    okLabel={t(
                        'frontend.price_offers.create.step_1.info_from_demand.your_costs.table.action.delete.confirm_dialog.ok_label'
                    )}
                    onConfirm={onClickDelete}
                >
                    {open => {
                        return (
                            <Tooltip content={t<string>('frontend.dashboard.price_offers.table.action.delete')}>
                                <Button variant='text' icon onClick={open} loading={isDeletingPriceOffer}>
                                    <TrashIcon />
                                </Button>
                            </Tooltip>
                        )
                    }}
                </ConfirmDialog>
            </div>
            {isModalVisible && (
                <PriceOfferSendToProductionModal
                    onSuccessSendToProduction={onSuccessSendToProduction}
                    priceOfferId={id}
                    show={isModalVisible}
                    onClose={() => {
                        return setIsModalVisible(false)
                    }}
                    orderId={variant.id}
                    step={priceOffer.step}
                />
            )}
            {isAddVariantModalVisible && (
                <PriceOfferAddVariantDialog
                    show={isAddVariantModalVisible}
                    onClose={() => setIsAddVariantModalVisible(false)}
                    variant={variant.variant}
                    orders={orders}
                    confirm={onClickCreateVariant}
                    confirmConnect={onClickCreateConnectVariant}
                    loading={isLoadingCreatingPriceOffer}
                    name={name}
                    numberOfPriceOffer={number}
                    variants={variants}
                />
            )}
        </div>
    )
}

export default PriceOfferTableActionsCell
