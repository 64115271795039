import {
    ColumnDef,
    ColumnFilter,
    ColumnSort,
    PaginationState,
    createColumnHelper,
    getCoreRowModel,
    useReactTable
} from '@tanstack/react-table'
import { PlainTableProps } from '../../../base/plain-table/PlainTable'
import { PriceOfferShort } from '../../../../services/api/price-offer/PriceOffer.types'
import { Row as TableRow } from '@tanstack/table-core/build/lib/types'
import { formatCurrency } from '../../../../utils/Helper'
import { formatDate } from '../../../../utils/DateHelper'
import { usePriceOfferTableContext } from './PriceOfferTable.context'
import { useTranslation } from 'next-i18next'
import { withPriceOfferTableProvider } from './PriceOfferTable.context'
import Button from '../../../base/button/Button'
import CardActions from '../../../base/card/CardActions'
import Column from '../../../base/grid/Column'
import Link from 'next/link'
import PriceOfferTableActionsCell from './PriceOfferTableActionsCell'
import PriceOfferTableCell from './PriceOfferTableCell'
import PriceOfferTableDemandsCell from './PriceOfferTableDemandsCell'
import PriceOfferTableNameCell from './PriceOfferTableNameCell'
import React, {
    Dispatch,
    ForwardedRef,
    ReactNode,
    SetStateAction,
    forwardRef,
    useCallback,
    useEffect,
    useImperativeHandle,
    useMemo,
    useState
} from 'react'
import Row from '../../../base/grid/Row'
import Table from '../../../base/table/Table'
import TablePagination from '../../../base/table/TablePagination'
import TruncateTooltip from '../../../base/truncate-tooltip/TruncateTooltip'
import useCreateTableState from '../../../../hooks/useCreateTableState'
import usePriceOfferPage from '../../../../hooks/api/price-offer/usePriceOfferPage'
import useTheme from '../../../../hooks/useTheme'
import useUserCurrency from '../../../../hooks/useUserCurrency'

export type PriceOfferTableChildren = (renderTable: () => ReactNode, renderPagination: () => ReactNode) => ReactNode

export type PriceOfferTableProps = Omit<PlainTableProps, 'children'> & {
    height?: number
    enablePagination?: boolean
    enableFilters?: boolean
    initialFilters?: ColumnFilter[]
    enableSorting?: boolean
    initialSorts?: ColumnSort[]
    onClickRow?: (order: TableRow<PriceOfferShort>) => void
    children?: PriceOfferTableChildren
    isPriceOfferDialog?: boolean
    customEmpty?: ReactNode
    dashboard?: boolean
    hasHeader?: boolean
    hasFiltersRow?: boolean
    rounded?: boolean
    pageSize?: number
    showShowAllAction?: boolean
    onSuccessSendToProduction?: () => void
}

export type PriceOfferTableRef = {
    setFilters: Dispatch<SetStateAction<ColumnFilter[]>>
    setSorts: Dispatch<SetStateAction<ColumnSort[]>>
    setPagination: Dispatch<SetStateAction<PaginationState>>
}

const PriceOfferTable = (
    {
        height,
        enablePagination = true,
        enableFilters = true,
        initialFilters = [],
        enableSorting = true,
        initialSorts = [],
        isPriceOfferDialog = false,
        onClickRow,
        children,
        customEmpty,
        dashboard = false,
        hasHeader = true,
        hasFiltersRow = true,
        pageSize = 15,
        showShowAllAction,
        onSuccessSendToProduction
    }: PriceOfferTableProps,
    ref: ForwardedRef<PriceOfferTableRef>
) => {
    const { t } = useTranslation()
    const { dateFormat } = useTheme()
    const currency = useUserCurrency()
    const { setHighlightedRow, highlightedRow } = usePriceOfferTableContext()
    const [rowNumberToHighlight, setRowNumberToHighlight] = useState<string>()
    const [windowWidth, setWindowWidth] = useState(window.innerWidth)

    const { filters, setFilters, sorts, setSorts, pagination, setPagination } = useCreateTableState({
        initialFilters,
        initialSorts,
        initialPagination: { pageSize, pageIndex: 0 }
    })

    useImperativeHandle(
        ref,
        () => {
            return {
                setSorts,
                setFilters,
                setPagination
            }
        },
        [setFilters, setPagination, setSorts]
    )

    const {
        data: { data: priceOffers = [], meta } = {},
        isLoading,
        refetch
    } = usePriceOfferPage({
        filters,
        sorts,
        pagination,
        onSuccess: () => {
            highlightedRow && highlightRow()
        }
    })

    const resetPaginationAndScrollOnTop = useCallback(() => {
        setPagination(prev => ({
            ...prev,
            pageIndex: 0
        }))

        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        })
    }, [setPagination])

    const findOfferNumberByVariantId = useCallback(
        (variantId: number): string | undefined => {
            const priceOffer = priceOffers.find(offer => offer.id === variantId)
            return priceOffer.number
        },
        [priceOffers]
    )
    const highlightRow = () => {
        const number = findOfferNumberByVariantId(highlightedRow)
        setRowNumberToHighlight(number)
        resetPaginationAndScrollOnTop()

        const timeoutId = setTimeout(() => {
            setHighlightedRow(undefined)
            setRowNumberToHighlight(undefined)
        }, 1000)

        return () => clearTimeout(timeoutId)
    }

    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth)
        }

        window.addEventListener('resize', handleResize)
        return () => window.removeEventListener('resize', handleResize)
    }, [])

    const actionsColumnSize = windowWidth >= 1300 ? 100 : 130

    const columns = useMemo<ColumnDef<PriceOfferShort>[]>(() => {
        const columnHelper = createColumnHelper<PriceOfferShort>()
        const columns = []

        {
            dashboard
                ? columns.push(
                      ...[
                          columnHelper.accessor('number', {
                              size: 130,
                              header: t('frontend.dashboard.price_offers.table.name_and_number'),
                              cell: ({ row }) => {
                                  return <PriceOfferTableNameCell row={row} />
                              },
                              enableSorting: false,
                              meta: {
                                  cellClassName: 'relative'
                              }
                          }),
                          columnHelper.accessor('createdAt', {
                              size: 60,
                              header: t('frontend.dashboard.price_offers.table.creation_date'),
                              cell: ({ row }) => {
                                  return (
                                      <PriceOfferTableCell
                                          row={row}
                                          getValue={data => {
                                              return <div>{formatDate(data.createdAt, dateFormat)}</div>
                                          }}
                                      />
                                  )
                              },
                              meta: {
                                  filtering: 'date'
                              }
                          }),
                          columnHelper.accessor('orders', {
                              size: 200,
                              header: t('frontend.dashboard.price_offers.table.demands'),
                              enableSorting: false,
                              cell: ({ row }) => {
                                  return (
                                      <PriceOfferTableDemandsCell
                                          dashboard
                                          row={row}
                                          refetchTableData={refetch}
                                          isPriceOfferDialog={isPriceOfferDialog}
                                      />
                                  )
                              }
                          }),
                          columnHelper.accessor('totalPrice', {
                              size: 80,
                              header: t('frontend.dashboard.price_offers.table.price'),
                              enableColumnFilter: false,
                              cell: ({ row }) => {
                                  return (
                                      <PriceOfferTableCell
                                          row={row}
                                          getValue={data => {
                                              return (
                                                  <div className='flex justify-end'>
                                                      {formatCurrency(data.totalPrice, currency)}
                                                  </div>
                                              )
                                          }}
                                      />
                                  )
                              }
                          })
                      ]
                  )
                : columns.push(
                      ...[
                          columnHelper.accessor('number', {
                              size: 130,
                              header: t('frontend.dashboard.price_offers.table.name_and_number'),
                              cell: ({ row }) => {
                                  return <PriceOfferTableNameCell row={row} />
                              },
                              enableSorting: false,
                              meta: {
                                  cellClassName: 'relative',
                                  filterTooltip: t('frontend.dashboard.price_offers.table.tooltip')
                              }
                          }),
                          columnHelper.accessor('createdAt', {
                              size: 91,
                              header: t('frontend.dashboard.price_offers.table.creation_date'),
                              cell: ({ row }) => {
                                  return (
                                      <PriceOfferTableCell
                                          row={row}
                                          getValue={data => {
                                              return formatDate(data.createdAt, dateFormat)
                                          }}
                                      />
                                  )
                              },
                              meta: {
                                  filtering: 'date'
                              }
                          }),
                          columnHelper.accessor('orders', {
                              size: 190,
                              header: t('frontend.dashboard.price_offers.table.demands'),
                              enableSorting: false,
                              cell: ({ row }) => {
                                  return (
                                      <PriceOfferTableDemandsCell
                                          row={row}
                                          refetchTableData={refetch}
                                          isPriceOfferDialog={isPriceOfferDialog}
                                      />
                                  )
                              },
                              meta: {
                                  filterTooltip: t('frontend.dashboard.demand.table.tooltip')
                              }
                          }),
                          columnHelper.accessor('totalPrice', {
                              size: 110,
                              header: t('frontend.dashboard.price_offers.table.price'),
                              enableColumnFilter: false,
                              cell: ({ row }) => {
                                  return (
                                      <PriceOfferTableCell
                                          row={row}
                                          getValue={data => {
                                              return formatCurrency(data.totalPrice, currency)
                                          }}
                                      />
                                  )
                              },
                              filterFn: 'equals',
                              meta: {
                                  filtering: 'number'
                              }
                          }),
                          columnHelper.accessor('note', {
                              size: 110,
                              header: t('frontend.dashboard.price_offers.table.note'),
                              cell: ({ row }) => {
                                  return (
                                      <PriceOfferTableCell
                                          row={row}
                                          getValue={data => {
                                              return <TruncateTooltip text={data.note} />
                                          }}
                                      />
                                  )
                              }
                          }),
                          columnHelper.display({
                              size: actionsColumnSize,
                              id: 'actions',
                              header: t('frontend.dashboard.price_offers.table.action'),
                              cell: ({ row }) => {
                                  return (
                                      <PriceOfferTableActionsCell
                                          row={row}
                                          isPriceOfferDialog={isPriceOfferDialog}
                                          onSuccessSendToProduction={onSuccessSendToProduction}
                                      />
                                  )
                              }
                          })
                      ]
                  )
        }

        return columns
    }, [currency, dateFormat, isPriceOfferDialog, refetch, dashboard, t])

    const table = useReactTable<PriceOfferShort>({
        columns,
        data: priceOffers,
        getCoreRowModel: getCoreRowModel(),
        pageCount: meta?.lastPage,
        state: {
            pagination,
            columnFilters: filters,
            sorting: sorts
        },
        onPaginationChange: setPagination,
        onColumnFiltersChange: setFilters,
        onSortingChange: setSorts,
        enableFilters,
        enableSorting,
        manualSorting: true,
        manualFiltering: true,
        manualPagination: true
    })
    const isEmpty = priceOffers.length === 0

    const renderPagination = () => {
        if (!enablePagination || isLoading) {
            return
        }
        return (
            <TablePagination
                table={table}
                totalItems={meta?.total || 0}
                itemsPerPage={meta?.perPage || 0}
                removePaddingX
            />
        )
    }

    const renderTable = () => {
        return (
            <Table
                instance={table}
                onClickRow={onClickRow}
                loading={isLoading}
                maxHeight={height}
                customEmpty={customEmpty}
                striped
                hasHeader={hasHeader}
                hasFiltersRow={hasFiltersRow}
                isRoundedRow={dashboard}
                shouldHighlightRow={row => row.original.number === rowNumberToHighlight}
            />
        )
    }

    const renderActions = () => {
        return (
            <>
                {showShowAllAction && (
                    <CardActions>
                        <Row justify='start'>
                            <Column>
                                <Button
                                    as={Link}
                                    href='/app/price-offers'
                                    variant='outlined'
                                    color='secondary'
                                    className='h-[41px] ml-1.5'
                                >
                                    {t('frontend.dashboard.order.table.actions.show_all')}
                                </Button>
                            </Column>
                        </Row>
                    </CardActions>
                )}
            </>
        )
    }

    if (children) {
        return children(renderTable, renderPagination) as JSX.Element
    }

    return (
        <>
            {renderTable()}
            {!dashboard && renderPagination()}
            {!isEmpty && renderActions()}
        </>
    )
}

export default withPriceOfferTableProvider<PriceOfferTableProps>(forwardRef(PriceOfferTable))
