import { FaPlus } from 'react-icons/fa'
import { PriceOfferOrderInfo, PriceOfferShort } from '../../../../services/api/price-offer/PriceOffer.types'
import { QueryObserverResult } from '@tanstack/react-query'
import { Row } from '@tanstack/react-table'
import { TrashIcon } from '@heroicons/react/outline'
import { formatCurrency } from '../../../../utils/Helper'
import { formatDate } from '../../../../utils/DateHelper'
import { usePriceOfferTableContext } from './PriceOfferTable.context'
import { useTranslation } from 'next-i18next'
import Button from '../../../base/button/Button'
import ConfirmDialog from '../../../base/confirm-dialog/ConfirmDialog'
import DemandIcon from '../../../base/icon/DemandIcon'
import Link from 'next/link'
import React, { useMemo, useRef, useState } from 'react'
import Tooltip, { TooltipRef } from '../../../base/tooltip/Tooltip'
import usePriceOfferDeleteOrder from '../../../../hooks/api/price-offer/usePriceOfferDeleteOrder'
import useTheme from '../../../../hooks/useTheme'
import useToast from '../../../../hooks/useToast'

export type PriceOfferTableDemandsCellProps = {
    row: Row<PriceOfferShort>
    refetchTableData?: () => Promise<QueryObserverResult<any, unknown>>
    isPriceOfferDialog?: boolean
    dashboard?: boolean
}

const PriceOfferTableDemandsCell = ({
    row: { original: priceOffer },
    refetchTableData,
    isPriceOfferDialog,
    dashboard
}: PriceOfferTableDemandsCellProps) => {
    const { variants, id, state } = priceOffer
    const { dateFormat } = useTheme()
    const toast = useToast()
    const { getSelectedVariant, showAddOrdersToPriceOfferDialog, setHighlightedRow } = usePriceOfferTableContext()
    const { mutate: deleteOrder, isLoading: isDeletingOrder } = usePriceOfferDeleteOrder()
    const { t } = useTranslation()

    const variant = useMemo<PriceOfferShort>(() => {
        const variant = getSelectedVariant(id, [priceOffer, ...priceOffer.variants])
        if (variant === '0') {
            return priceOffer
        }
        return variants.find(item => {
            return item.variant === variant
        })
    }, [getSelectedVariant, id, priceOffer, variants])

    const renderAddDemand = () => {
        return (
            <Button
                leftIcon={<FaPlus />}
                className='!pl-6 !font-light !text-xs !items-center'
                key='add-new-demand'
                variant='outlined'
                size='md'
                onClick={event => {
                    event.stopPropagation()
                    showAddOrdersToPriceOfferDialog(variant.id)
                }}
            >
                {t('frontend.dashboard.price_offers.table.demands.add_action')}
            </Button>
        )
    }
    const [isOpenDeleteDialog, setIsOpenDeleteDialog] = useState(false)
    const tooltipRef = useRef<TooltipRef>(null)

    const renderDemand = (demand: PriceOfferOrderInfo) => {
        const renderDemandTooltipContent = () => {
            return (
                <div className='p-2'>
                    <ul className='flex flex-col gap-0.5'>
                        <li className='text-xs font-medium'>
                            Datum vytvoření: {formatDate(demand.createdAt, dateFormat)}
                        </li>
                        <li className='text-xs font-medium'>Cena: {formatCurrency(demand.price, demand.currency)}</li>
                    </ul>
                    <div className='flex flex-col'>
                        <Button
                            as={Link}
                            href={state === 'ordered' ? `/app/order/${demand.id}` : `/app/demand/${demand.id}`}
                            variant='outlined'
                            className='mt-3 !text-xs !font-semibold'
                            color='secondary'
                        >
                            {t('frontend.dashboard.price_offers.table.detail_demand')}
                        </Button>

                        {!isPriceOfferDialog && (
                            <ConfirmDialog
                                text={
                                    state === 'ordered'
                                        ? t('frontend.dashboard.price_offers.table.delete_order_dialog')
                                        : t('frontend.dashboard.price_offers.table.delete_demand_dialog')
                                }
                                okLabel={t('frontend.dashboard.price_offers.table.action.delete_dialog_okLabel')}
                                onConfirm={() => {
                                    deleteOrder(
                                        {
                                            id: variant.id,
                                            orderId: demand.id
                                        },
                                        {
                                            onSuccess: async () => {
                                                toast.success(
                                                    t(
                                                        'frontend.dashboard.price_offers.table.action.delete.success_toast'
                                                    )
                                                )
                                                await refetchTableData()
                                                setHighlightedRow(variant.id)
                                            }
                                        }
                                    )
                                    setIsOpenDeleteDialog(false)
                                    tooltipRef.current.close()
                                }}
                                onCancel={() => {
                                    setIsOpenDeleteDialog(false)
                                    tooltipRef.current.close()
                                }}
                            >
                                {open => {
                                    return (
                                        <Button
                                            leftIcon={<TrashIcon />}
                                            variant='outlined'
                                            color='danger'
                                            className='mt-3 !text-xs !font-semibold'
                                            loading={isDeletingOrder}
                                            onClick={event => {
                                                event.stopPropagation()
                                                open()
                                                setIsOpenDeleteDialog(true)
                                            }}
                                        >
                                            Odebrat
                                        </Button>
                                    )
                                }}
                            </ConfirmDialog>
                        )}
                    </div>
                </div>
            )
        }

        const renderOrderedDemandTooltipContent = () => {
            return (
                <div className='p-2'>
                    <ul className='flex flex-col gap-0.5'>
                        <li className='text-xs font-medium'>
                            Datum vytvoření: {formatDate(demand.createdAt, dateFormat)}
                        </li>
                        <li className='text-xs font-medium'>Cena: {formatCurrency(demand.price, demand.currency)}</li>
                    </ul>
                    <div className='flex flex-col'>
                        <Button
                            as={Link}
                            href={state === 'ordered' ? `/app/order/${demand.id}` : `/app/demand/${demand.id}`}
                            variant='outlined'
                            className='mt-3 !text-xs !font-semibold'
                            color='secondary'
                        >
                            {t('frontend.dashboard.price_offers.table.detail_order')}
                        </Button>
                    </div>
                </div>
            )
        }

        return (
            <Tooltip
                ref={tooltipRef}
                backgroundColor='white'
                content={state === 'ordered' ? renderOrderedDemandTooltipContent() : renderDemandTooltipContent()}
                key={demand.id}
                disableAutoHide={isOpenDeleteDialog}
            >
                <div className='inline-flex items-center justify-between gap-1.5 w-full'>
                    <div className='flex flex-col text-xs'>
                        <ul className='w-full flex flex-col'>
                            <div className='flex items-center'>
                                <DemandIcon fill='black' className='!h-[16px] !w-[12px]mr-0' />
                                <li className='text-grey-600 font-bold ml-[-12px]'>{demand.productName}</li>
                            </div>

                            <li className='text-grey-600 font-normal ml-5'>
                                {demand.customLabel ? `${demand.customLabel} (${demand.number})` : demand.number}
                            </li>
                        </ul>
                    </div>
                </div>
            </Tooltip>
        )
    }

    return (
        <div className='inline-block '>
            <div className='flex flex-col gap-2.5 py-2 '>{variant.orders.map(renderDemand)}</div>
            <div>{!isPriceOfferDialog && !dashboard && state === 'saved' && renderAddDemand()}</div>
        </div>
    )
}

export default PriceOfferTableDemandsCell
